import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { Button, message } from "antd";
import {
  SectionHeader,
  Label,
  FieldRow,
  Value,
  TextInput,
  ErrorHint,
} from "../Components/Order.Widget";
import * as Theme from "../Theme";

class ChangePwErr extends Error {}

function errorHandler(err) {
  if (err instanceof ChangePwErr) {
    message.error(err.message);
  } else if (err.error == "invalid_code") {
    message.error("舊密碼錯誤");
  } else {
    message.error("修改時發生錯誤");
  }
}

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      checkNewPassword: "",
      valid: false,
    };
  }
  render() {
    let { valid, password, newPassword, checkNewPassword } = this.state;
    let { profile } = this.props;
    if (profile.social_type !== "default") {
      return (
        <Wrapper>
          <SectionHeader>重設登入密碼</SectionHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 40,
            }}
          >
            <img
              width="60px"
              height="60px"
              src={`../../images/${profile.social_type}-signin.png`}
            />
            <div style={{ color: Theme.colors.blueGreen, marginTop: 20 }}>
              此帳號為 {profile.social_type} Social login 方式登入
            </div>
            <div style={{ color: Theme.colors.blueGreen, marginTop: 5 }}>
              無修改密碼服務
            </div>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <SectionHeader style={{ marginTop: 35 }}>重設登入密碼</SectionHeader>
        <Section>
          <FieldRow>
            <Label>舊的密碼</Label>
            <TextInput
              type="password"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
            />
          </FieldRow>
          <FieldRow>
            <Label>新的密碼</Label>
            <TextInput
              type="password"
              value={newPassword}
              onChange={e => this.setState({ newPassword: e.target.value })}
            />
          </FieldRow>
          <FieldRow>
            <Label>密碼確認</Label>
            <TextInput
              type="password"
              value={checkNewPassword}
              onChange={e =>
                this.setState({ checkNewPassword: e.target.value })
              }
            />
            {valid && checkNewPassword !== newPassword && (
              <ErrorHint message="確認密碼與新密碼不相同" />
            )}
          </FieldRow>
          <FieldRow>
            <div style={{ flex: 1 }} />
            <Button
              type="primary"
              style={{
                flexGrow: 0,
                backgroundColor: Theme.colors.main,
                border: 0,
                padding: "7px 40px",
                height: "auto",
              }}
              onClick={this._submit}
            >
              修改登入密碼
            </Button>
          </FieldRow>
        </Section>
      </Wrapper>
    );
  }

  _submit = async () => {
    let { appActions, profile } = this.props;
    let { newPassword, password } = this.state;
    this.setState({ valid: true });
    try {
      this._valid();
      let res = await appActions.changePassword({
        username: profile.user.username,
        password,
        newPassword,
      });
      message.success("修改密碼成功！");
      this.setState({
        password: "",
        newPassword: "",
        checkNewPassword: "",
        error: "",
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  _valid = () => {
    //put any verification algo here
    let { newPassword, checkNewPassword } = this.state;
    if (newPassword !== checkNewPassword) {
      throw new ChangePwErr("確認密碼與新密碼不相同！");
    }
  };
}

const Wrapper = styled.div``;
const Section = styled.div`
  padding: 20px;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ChangePasswordForm);
