import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension"; // XXX: import useDimension directly because parent components haven't hookify
import * as Cart from "../Contexts/CartContext";

function ProfileDash(props) {
  const { dimension, rwd } = useDimension();
  const [pendingOrders, setPendingOrders] = React.useState([]);

  let block_width = 236;
  let block_height = 100;
  if (rwd === "pad" || rwd === "mobile") {
    block_width = 165;
  }
  React.useEffect(() => {
    async function getPendingOrdersLength() {
      let params = {
        //等待付款，不合格，未上傳稿件
        display_state: "payment_waiting,review_rejected,review_waiting",
        no_page: true,
      };
      let pendingOrders = await Cart.Actions.fetchOrders(params);
      setPendingOrders(pendingOrders);
    }
    getPendingOrdersLength();
  }, []);
  return (
    <Wrapper blockWidth={block_width} blockHeight={block_height}>
      <div className="blocks-row">
        <div className="data-block">
          <div className="label">會員編號</div>
          <div className="data" style={{ lineHeight: "34px" }}>
            #{props.profile.id}{" "}
            {props.profile.social_type === "google"
              ? "Google 登入"
              : props.profile.social_type === "facebook"
              ? "FB 登入"
              : "一般信箱登入"}{" "}
          </div>
        </div>
        <div className="data-block">
          <div className="label">回饋感官印幣</div>
          <div className="data" style={{ fontSize: 24, fontWeight: 500 }}>
            {props.profile.bonus}
            <p>元</p>
          </div>
        </div>
      </div>
      <div className="blocks-row">
        <div className="data-block">
          <div className="label">待處理訂單</div>
          <div className="data" style={{ fontSize: 24, fontWeight: 500 }}>
            {pendingOrders.length}
            <p>筆</p>
          </div>
        </div>
        <div className="data-block">
          <div className="label">不合格訂單</div>
          <div className="data" style={{ fontSize: 24, fontWeight: 500 }}>
            {pendingOrders.filter(order => order.is_item_rejected).length}
            <p>筆</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
  & > .blocks-row {
    display: flex;
    flex-wrap: nowrap;
    flex: 1;

    & > .data-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;
      background-color: ${Theme.colors.sub};
      flex-basis: ${props => props.blockWidth}px;
      height: ${props => props.blockHeight}px;

      & > .label {
        color: ${Theme.colors.main};
      }
      & > .data {
        color: ${Theme.colors.brown};
        display: flex;
        align-items: baseline;
        & > p {
          margin: 0px 0px 0px 8px;
          color: ${Theme.colors.main};
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    flex-direction: column;
    & > .blocks-row {
      margin-bottom: ${Theme.rwdPadding.outer};
    }
  }
`;

export default ProfileDash;
