import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import {
  SectionHeader,
  FieldRow,
  Value,
  Label,
  TextInput,
  ErrorHint,
} from "./Order.Widget";
import * as Theme from "../Theme";
const validator = require("validator");

let STATUS = {
  //accepted, rejected, pending
  accepted: "申請成功",
  rejected: "申請失敗",
  pending: "等待審核中",
};

class RequestEntPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upgradeData: null,
      formData: {},
      editable: true,
      doValidate: false,
    };
  }

  componentDidMount() {
    this._getUpgradeData();
  }

  render() {
    let { upgradeData, formData, doValidate, editable } = this.state;
    let { isEmpty, isNumeric } = validator;
    return (
      <Wrapper>
        <SectionHeader style={{ marginTop: 35 }}>企業會員申請表</SectionHeader>
        <Section>
          <FieldRow>
            <Label>公司名稱*</Label>
            <TextInput
              disabled={!editable}
              placeholder="公司名稱"
              value={formData.ent_name ? formData.ent_name : ""}
              onChange={e => this._onFieldChange("ent_name", e.target.value)}
            />
            {doValidate &&
              isEmpty(formData.ent_name) &&
              ErrorHint({ message: "公司名稱不能為空" })}
          </FieldRow>
          <FieldRow>
            <Label>統一編號*</Label>
            <TextInput
              disabled={!editable}
              value={formData.gui_number ? formData.gui_number : ""}
              placeholder="統一編號"
              onChange={e => this._onFieldChange("gui_number", e.target.value)}
            />
            {doValidate &&
              isEmpty(formData.gui_number) &&
              ErrorHint({ message: "統一編號不能為空" })}
          </FieldRow>
          <FieldRow>
            <Label>公司電話*</Label>
            <TextInput
              disabled={!editable}
              value={formData.ent_tel ? formData.ent_tel : ""}
              placeholder="公司電話"
              onChange={e => this._onFieldChange("ent_tel", e.target.value)}
            />
            <TextInput
              disabled={!editable}
              type="short"
              style={{ marginLeft: 10 }}
              placeholder="分機"
              value={formData.tel_ext ? formData.tel_ext : ""}
              onChange={e => this._onFieldChange("tel_ext", e.target.value)}
            />

            {doValidate &&
              (() => {
                if (isEmpty(formData.ent_tel)) {
                  return ErrorHint({ message: "公司電話不能為空" });
                } else if (!isNumeric(formData.ent_tel)) {
                  return ErrorHint({ message: "請確認公司電話格式" });
                }
              })()}
          </FieldRow>
          <FieldRow>
            <Label>公司地址*</Label>
            <TextInput
              disabled={!editable}
              placeholder="公司地址"
              value={formData.ent_address ? formData.ent_address : ""}
              onChange={e => this._onFieldChange("ent_address", e.target.value)}
            />
            {doValidate &&
              isEmpty(formData.ent_address) &&
              ErrorHint({ message: "公司地址不能為空" })}
          </FieldRow>

          <FieldRow>
            <div style={{ flex: 1 }} />
            <Ant.Button
              disabled={!editable}
              type="primary"
              style={{
                flexGrow: 0,
                color: "#707070",
                backgroundColor: editable ? Theme.colors.lightGreen : "#eee",
                border: 1,
                padding: "7px 40px",
                height: "auto",
              }}
              onClick={this._submit}
            >
              {editable ? `送出企業會員申請` : `已送出企業會員申請`}
            </Ant.Button>
          </FieldRow>
          {upgradeData && (
            <FieldRow>
              <Label>申請狀態</Label>
              <Label>{STATUS[upgradeData.state]}</Label>
            </FieldRow>
          )}
          {upgradeData?.state === "rejected" && (
            <FieldRow>
              <Label>拒絕原因</Label>
              <Label>{upgradeData.reason}</Label>
            </FieldRow>
          )}
        </Section>
      </Wrapper>
    );
  }

  _getUpgradeData = async () => {
    let { appActions } = this.props;
    let upgradeData = await appActions.getMyLatestUpgradeUserTypeRecord();
    let form_data = this._parseData(upgradeData.data || {});
    if (upgradeData.state === "pending" || upgradeData.state === "rejected") {
      this.setState({ editable: false });
    }
    this.setState({ formData: form_data, upgradeData });
  };

  _parseData = data => {
    const defaultValue = {};
    let parsed = null;
    try {
      parsed = JSON.parse(data);
      if (Object.keys(parsed).length < 1) {
        parsed = defaultValue;
      }
    } catch (ex) {
      parsed = defaultValue;
    }
    return parsed;
  };

  _checkDataValidated = () => {
    let { isEmpty, isNumeric } = validator;
    let { formData } = this.state;
    this.setState({ doValidate: true });
    if (
      isEmpty(formData.ent_name) ||
      isEmpty(formData.gui_number) ||
      isEmpty(formData.ent_tel) ||
      isEmpty(formData.ent_address)
    ) {
      return false;
    } else if (
      !isNumeric(formData.gui_number) ||
      !isNumeric(formData.ent_tel)
    ) {
      return false;
    }
    return true;
  };

  _submit = async () => {
    let { formData } = this.state;
    let { appActions } = this.props;
    if (this._checkDataValidated()) {
      try {
        await appActions.requestToUpgradeUserType({
          data: JSON.stringify(formData),
        });
        Ant.message.success("申請資料已送出");
        await appActions.getMyProfile();
      } catch (err) {
        console.warn(err);
      }
    }
  };

  _onFieldChange = (field, value) => {
    let { formData } = this.state;

    formData[field] = value;
    this.setState({
      formData,
      doValidate: false,
    });
  };
}

const Wrapper = styled.div``;
const Section = styled.div`
  padding: 20px;
  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 10px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(RequestEntPanel);
