import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import InvoiceForm, { valid as invoiceValid } from "../Components/InvoiceForm";
import * as Cart from "../Contexts/CartContext";
import * as Theme from "../Theme";
import * as Ant from "antd";
import {
  SectionHeader,
  Label,
  FieldRow,
  Value,
  TextInput,
  Hint,
  ErrorHint,
} from "../Components/Order.Widget";
import { isNotEmpty, isMobileNumber } from "../Domain/FormatValidator";
import AddressField from "../Components/AddressField";
import { Context } from "../AppContext";
import { ErrProfile, errorHandler } from "../errors";

const GENERAL_FIELD = [
  // { name: "avatar", type: "file", label: "會員頭像" },
  { name: "name", type: "text", label: "會員名稱" },
  { name: "phone", type: "text", label: "手機號碼" },
  // { name: "address", type: "textarea", label: "聯絡地址" },
  // { name: "email", type: "text", label: "電子信箱" }, can not edit email it's account
];

const ENTERPRISE_FIELD = [
  { name: "ent_name", type: "text", label: "公司名稱" },
  { name: "gui_number", type: "text", label: "統一編號" },
  {
    name: "ent_tel",
    type: "text",
    label: "公司電話",
    children: [{ name: "tel_ext", type: "text", label: "分機" }],
  },
  // { name: "ent_address", type: "text", label: "公司地址" },
];

function ProfilePanel(props) {
  const app = React.useContext(Context);
  const { profile } = app.state;
  const [values, setValues] = React.useState(null);
  const [is_ent, setIsEnt] = React.useState(false);
  const [config, setConfig] = React.useState(null);

  React.useEffect(() => {
    try {
      app.actions.setLoading(true);

      //profile initialization
      let is_ent = false;
      let values = {};

      if (profile) {
        is_ent = [
          "enterprise",
          "ent_vip",
          "ent_vip_monthly",
          "monthly",
        ].includes(profile.user_type);

        values = { ...profile };
        if (is_ent) {
          let data = {};
          try {
            data = JSON.parse(values.data);
          } catch (error) {
            Ant.message.warning("取得企業資訊錯誤");
          }
          data.gui_number = data.gui_number || values.uni_numbers;
          values = {
            ...values,
            ...data,
          };
        }
        setValues(values);
        setIsEnt(is_ent);
      }

      //invoice initailization
      (async () => {
        let resp = await Cart.Actions.fetchCart();
        if (resp && resp.config.invoiceConfig) {
          setConfig(resp.config.invoiceConfig);
        } else {
          setConfig({
            config: {
              invoice_type: "two_copies",
              invoice_subtype: "ezpay_vehicle",
              invoice_detail: "",

              citizen_personal_certificate_code: "", //自然人憑證條碼(16碼)
              mobile_vehicle_code: "", //手機載具(8碼)
              ezpay_vehicle_code: "",

              gui_number: "",
              company_title: "",

              donate_foundation_gui: "",
              love_code: "",
            },
          });
        }
      })();
    } catch (ex) {
      Ant.message.error("取的資料錯誤");
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, [profile]);

  const valid = React.useCallback(
    values => {
      let { name, phone, address, city, district } = values;
      if (!isNotEmpty(city) || !isNotEmpty(district)) {
        throw new ErrProfile("縣市、鄉鎮市區必須填寫");
      }

      if (!isNotEmpty(name) || !isNotEmpty(phone) || !isNotEmpty(address)) {
        throw new ErrProfile("你的名字、聯絡電話、聯絡地址必須填寫。");
      }

      if (!isMobileNumber(phone)) {
        throw new ErrProfile("聯絡電話必須為手機格式");
      }

      if (is_ent) {
        const { ent_city, ent_district } = values;

        if (!isNotEmpty(ent_city) || !isNotEmpty(ent_district)) {
          throw new ErrProfile('"縣市、鄉鎮市區必須填寫');
        }
      }
    },
    [is_ent]
  );

  const editProfile = async () => {
    app.actions.setLoading(true);

    let _values = {
      name: values.name,
      phone: values.phone,
      city: values.city,
      district: values.district,
      zip_code: values.zip_code,
      address: values.address,
      data: values.data,
      ent_name: values.ent_name,
      gui_number: values.gui_number,
      ent_tel: values.ent_tel,
      tel_ext: values.tel_ext,
      ent_zip_code: values.ent_zip_code,
      ent_city: values.ent_city,
      ent_district: values.ent_district,
      ent_address: values.ent_address,
    };
    if (is_ent) {
      _values.data = JSON.stringify({
        ent_name: values.ent_name,
        gui_number: values.gui_number,
        ent_tel: values.ent_tel,
        tel_ext: values.tel_ext,
        ent_zip_code: values.ent_zip_code,
        ent_city: values.ent_city,
        ent_district: values.ent_district,
        ent_address: values.ent_address,
      });
    }

    try {
      valid(_values);
      await app.actions.editMyProfile(_values);
      Ant.message.success("修改成功");
    } catch (err) {
      errorHandler(err, "修改個人資料錯誤");
    }

    app.actions.setLoading(false);
  };

  const editInvoice = async () => {
    app.actions.setLoading(true);
    try {
      if (!invoiceValid(config)) {
        app.actions.setLoading(false);
        return;
      }

      let resp = await Cart.Actions.editConfig({ invoiceConfig: config });
      Ant.message.success("修改成功");
    } catch (ex) {
      console.warn(ex);
      Ant.message.error("修改失敗");
    }
    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <SectionHeader style={{ marginTop: 0 }}>會員資料</SectionHeader>
      <Section>
        {values &&
          GENERAL_FIELD.map((field, idx) => (
            <FieldRow key={idx}>
              <Label>{field.label}</Label>
              <TextInput
                type="long"
                placeholder={field.label}
                value={values[field.name]}
                onChange={e => {
                  const value = e.target.value;
                  setValues(prev => ({ ...prev, [field.name]: value }));
                }}
              />
              {/* {field.name === "name" && (
                <Hint>＊會員名稱建議填寫本名，避免造成無法取件之情形。</Hint>
              )} */}
            </FieldRow>
          ))}

        {values && (
          <FieldRow>
            <Label>電子信箱</Label>
            <TextInput
              type="long"
              disabled={true}
              value={values["email"]}
              onChange={e => {}}
            />
          </FieldRow>
        )}

        {values && (
          <FieldRow>
            <Label>聯絡地址</Label>
            <AddressField
              zip_code={values.zip_code}
              city={values.city}
              district={values.district}
              address={values.address}
              onChange={data => setValues(prev => ({ ...prev, ...data }))}
            />
          </FieldRow>
        )}

        {values &&
          is_ent &&
          ENTERPRISE_FIELD.map((field, idx) => (
            <FieldRow key={idx}>
              <Label>{field.label}</Label>
              <TextInput
                placeholder={field.label}
                value={values[field.name]}
                onChange={e =>
                  setValues(prev => ({ ...prev, [field.name]: e.target.value }))
                }
              />
              {field.children &&
                field.children.map((child, idx) => (
                  <TextInput
                    key={idx}
                    placeholder={child.label}
                    value={values[child.name]}
                    type="short"
                    onChange={e =>
                      setValues(prev => ({
                        ...prev,
                        [child.name]: e.target.value,
                      }))
                    }
                  />
                ))}
            </FieldRow>
          ))}

        {values && is_ent && (
          <FieldRow>
            <Label>公司地址</Label>
            <AddressField
              zip_code={values.ent_zip_code}
              city={values.ent_city}
              district={values.ent_district}
              address={values.ent_address}
              onChange={data =>
                setValues(prev => ({
                  ...prev,
                  ...(data.zip_code !== undefined && {
                    ent_zip_code: data.zip_code,
                  }),
                  ...(data.city !== undefined && { ent_city: data.city }),
                  ...(data.district !== undefined && {
                    ent_district: data.district,
                  }),
                  ...(data.address !== undefined && {
                    ent_address: data.address,
                  }),
                }))
              }
            />
          </FieldRow>
        )}

        <FieldRow>
          <div style={{ flex: 1 }} />
          <Button onClick={editProfile}>修改會員資訊</Button>
        </FieldRow>
      </Section>
      <SectionHeader>發票資料</SectionHeader>
      <Section>
        {config && (
          <React.Fragment>
            <InvoiceForm
              config={config}
              setConfig={data => {
                setConfig(data);
              }}
            />

            <FieldRow>
              <div style={{ flex: 1 }} />
              <Button onClick={editInvoice}>修改發票資訊</Button>
            </FieldRow>
          </React.Fragment>
        )}
      </Section>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const Section = styled.div`
  padding: 20px;
  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 0px;
  }
`;

const Button = styled(Ant.Button).attrs({
  type: "primary",
})`
  flex-grow: 0;
  background-color: ${Theme.colors.lightGreen};
  color: ${Theme.colors.text};
  border: 0;
  padding: 7px 40px;
  height: auto;
`;

export default connect((state, ownProps) => ({}), ActionCreator)(ProfilePanel);
