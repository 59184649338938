import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";
import {
  SectionHeader,
  FieldRow,
  Value,
  Label,
  TextInput,
  Divider,
  SelectOption,
  Select,
  Hint,
} from "./Order.Widget";
import * as Theme from "../Theme";

class RequestMonthlyPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: true,
      haveRead: false,
      upgradeData: null,
      formData: {},
    };
  }

  componentDidMount() {
    this._getUpgradeData();
    let monthly_data = {};
  }

  render() {
    let { formData, editable, haveRead } = this.state;

    return (
      <Wrapper>
        <Description>
          <h4>月結會員專屬服務</h4>
          <div className="content">
            <p>• 運費每月結算一次，提供您方便又省時的服務。</p>
            <p>• 提供您每月帳單報表資料。</p>
            <p>• 享有30天還款期，提高企業資金效益。</p>
            <p>• 優先獲取平台相關業務信息或資料。</p>
            <p>• 優先體驗和試用新服務。</p>
            <p>• 擁有比普通客戶更多、更方便的增值服務選擇。</p>
          </div>
          <h4>申請加入條件</h4>
          <div className="content">
            <p>• 申請開通月結帳戶前，必須先擁有本服務企業會員帳號。</p>
            <p>
              •
              月結會員僅限於公司、商行、工廠及協會等具有統一編號之營利事業或財團法人。
            </p>
            <p>• 月結會員申請時填寫之相關資料使用適用於本服務之隱私權政策。</p>
          </div>
          <h4>申請準備資料</h4>
          <div className="content">
            <p>
              若您符合以上申請條件，請填寫『企業月結會員申請』資料，並備妥以下資料檔案掃描檔或影本：
            </p>
            <p>1. 勾選同意下方月結服務說明，並填寫『月結會員申請』資料</p>
            <p>
              2.
              公司具有營業登記、商業登記、稅籍登記、財團法人及社團法人的登記(以上檔案擇一)，需持有具法律效力登記證明的營業執照。
            </p>
            <p>3. 提供公司行號支存帳號資掃描檔或影本。</p>
            <p>以上資料送審後，我們將會盡快審核您提出的申請。</p>
          </div>
          <h4>如何變更月結帳戶資料?</h4>
          <div className="content">
            <p>
              如您有財務資料或公司基本資料異動需求，請寄送申請變更月結會員資料寄送電子郵件：service@ssprint.com.tw，
            </p>
            <p>
              或請於平台服務時間來電客服專線：02-2736-7701，由專人為您服務修改。
            </p>
          </div>
        </Description>

        <SectionHeader style={{ marginTop: 0 }}>月結服務申請表</SectionHeader>
        <Section>
          <FieldRow>
            <Label>會員名稱</Label>
            <TextInput
              disabled={!editable}
              value={formData.contact_name}
              placeholder="會員名稱"
              onChange={e =>
                this._onFieldChange("contact_name", e.target.value)
              }
            />
            <Hint>＊企業會員之名稱即公司聯絡人名稱</Hint>
          </FieldRow>
          <FieldRow>
            <Label>公司抬頭</Label>
            <TextInput
              disabled={!editable}
              value={formData.ent_name}
              placeholder="公司抬頭"
              onChange={e => this._onFieldChange("ent_name", e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <Label>公司統編</Label>
            <TextInput
              disabled={!editable}
              value={formData.gui_number}
              placeholder="公司統編"
              onChange={e => this._onFieldChange("gui_number", e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <Label>電子信箱</Label>
            <TextInput
              disabled={!editable}
              value={formData.email}
              placeholder="電子信箱"
              onChange={e => this._onFieldChange("email", e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <Label>產業類型</Label>
            <Select
              disabled={!editable}
              value={formData.industry_type}
              onChange={value => this._onFieldChange("industry_type", value)}
            >
              <SelectOption value="文化創意／藝文相關">
                文化創意／藝文相關
              </SelectOption>
              <SelectOption value="印刷同業">印刷同業</SelectOption>
              <SelectOption value="廣告行銷">廣告行銷</SelectOption>
              <SelectOption value="設計公司">設計公司</SelectOption>
              <SelectOption value="個人工作室／SOHO">
                個人工作室／SOHO
              </SelectOption>
              <SelectOption value="其他">其他</SelectOption>
            </Select>
          </FieldRow>
          <FieldRow>
            <Label>公司電話</Label>
            <TextInput
              disabled={!editable}
              value={formData.ent_tel}
              placeholder="公司電話"
              onChange={e => this._onFieldChange("ent_tel", e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <Label>傳真號碼</Label>
            <TextInput
              disabled={!editable}
              value={formData.fax}
              placeholder="傳真號碼"
              onChange={e => this._onFieldChange("fax", e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <Label>公司地址</Label>
            <TextInput
              disabled={!editable}
              value={formData.address}
              placeholder="公司地址"
              onChange={e => this._onFieldChange("address", e.target.value)}
            />
          </FieldRow>
          {/* <FieldRow>
            <Label>發票形式</Label>
            <Select
              disabled={!editable}
              value={data.receipt_type}
              onChange={value => this._onFieldChange("receipt_type", value)}
            >
              <SelectOption value="二聯">二聯</SelectOption>
              <SelectOption value="三聯">三聯</SelectOption>
            </Select>
          </FieldRow>
          <FieldRow>
            <Label>公司負責人 手機 / 電話</Label>
            <TextInput
              disabled={!editable}
              value={data.principal_tel}
              onChange={e =>
                this._onFieldChange("principal_tel", e.target.value)
              }
            />
          </FieldRow>
          <FieldRow>
            <Label>聯絡人 手機 / 電話</Label>
            <TextInput
              disabled={!editable}
              value={data.contact_tel}
              onChange={e => this._onFieldChange("contact_tel", e.target.value)}
            />
          </FieldRow> */}
          <Divider />
          <FieldRow style={{ alignItems: "flex-start", flexWrap: "nowrap" }}>
            <Checkbox
              checked={haveRead}
              onChange={e => this.setState({ haveRead: e.target.checked })}
            ></Checkbox>
            <div style={{ marginLeft: 10 }}>
              <p>
                申請前請務必至詳細閱讀『 客服中心{">"}企業會員交易須知
                』，以免影響您的權益謝謝。
              </p>
              <p>
                我已閱讀以上月結服務說明，同意遵守『 月結客戶付款注意事項
                』，並申請成為服務平台之企業月結會員。
              </p>
            </div>
          </FieldRow>
          <FieldRow>
            <div style={{ flex: 1 }} />
            <Ant.Button
              type="primary"
              disabled={!editable}
              style={{
                flexGrow: 0,
                border: 0,
                padding: "7px 40px",
                height: "auto",
              }}
              onClick={this._submit}
            >
              {editable ? "送出月結會員申請" : "已送出月結會員申請"}
            </Ant.Button>
          </FieldRow>
        </Section>
      </Wrapper>
    );
  }

  _getUpgradeData = async () => {
    let { appActions } = this.props;
    let latestUpgradeRecord = await appActions.getMyLatestUpgradeUserTypeRecord();
    if (Object.keys(latestUpgradeRecord).length > 0) {
      let formData = this._parseData(latestUpgradeRecord.data);
      this.setState({
        editable: false,
        formData,
        upgradeData: latestUpgradeRecord,
      });
      return;
    }
  };

  _parseData = data => {
    const defaultValue = {};
    let parsed = null;
    try {
      parsed = JSON.parse(data);
      if (Object.keys(parsed).length < 1) {
        parsed = defaultValue;
      }
    } catch (ex) {
      parsed = defaultValue;
    }
    return parsed;
  };

  _submit = async () => {
    let { formData, haveRead } = this.state;
    let { appActions } = this.props;

    if (!haveRead) {
      alert("請先閱讀完說明");
      return;
    }
    try {
      await appActions.requestToUpgradeUserType({
        data: JSON.stringify(formData),
      });

      Ant.message.success("申請資料已送出");
      this._getUpgradeData();
    } catch (err) {
      console.warn(err);
    }
  };

  _onFieldChange = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: value,
      },
    });
  };
}

const Wrapper = styled.div``;
const Section = styled.div`
  padding: 20px;
`;

const Description = styled.div`
  margin: 20px;

  & > h4 {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  & > .content {
    margin-bottom: 20px;
    & > p {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
`;

const Checkbox = styled(Ant.Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Theme.colors.main};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(RequestMonthlyPanel);
